/**
 * Klaviyo Back in Stock Web Component
 * Handles both displaying the popup and sending BIS requests to Klaviyo
 */
import { subscribe } from '../helper/pubsub';
import { PUB_SUB_EVENTS } from '../helper/constants';

// Set up a global listener for product:updated events (only once)
// This addresses the issue with color swatch clicks loading new products
if (!window.klaviyoBisProductUpdatedHandler) {
  document.addEventListener('product:updated', (event) => {
    if (event.detail && event.detail.sectionId) {
      console.log('Product updated event detected for section:', event.detail.sectionId);

      // Find all Klaviyo BIS components that match this section ID
      document.querySelectorAll('klaviyo-bis').forEach(component => {
        // Check if this component is associated with the updated product form
        if (component.getAttribute('data-product-form-id') === event.detail.sectionId) {
          // Reconnect this component
          component.reconnectAfterProductUpdate(event.detail.variantId);
        }
      });
    }
  });
  window.klaviyoBisProductUpdatedHandler = true;
}

class BISForm extends HTMLElement {
  constructor() {
    super();

    // Store reference to the product form ID this component is associated with
    this.productFormId = this.getAttribute('data-product-form-id');

    // Store the unique ID for this instance (matches the one generated in liquid)
    this.uniqueId = this.id;

    // Store current variant data (only for display purposes)
    this.currentVariant = null;

    // Set up form submission handler
    this.form = this.querySelector('form');
    this.form.addEventListener('submit', this.onSubmitHandler.bind(this));

    // Set up close buttons
    this.querySelectorAll('.klaviyo-bis-close').forEach((elem) => {
      elem.addEventListener('click', this.onClickHandler.bind(this));
    });

    // Set up the event listener for BIS triggers
    this.setupBisTriggers();

    // Subscribe to variant changes just to get variant title
    this.subscribeToVariantChanges();

    console.log(`Klaviyo BIS component initialized for product form: ${this.productFormId}`);
  }

  /**
   * Public method called when a product is updated via color swatch click
   * Reconnects event listeners and updates internal state
   */
  reconnectAfterProductUpdate(variantId) {
    console.log(`Reconnecting Klaviyo BIS after product update for ${this.productFormId}`);

    // Update our stored product form ID in case it changed
    this.productFormId = this.getAttribute('data-product-form-id');

    // Update our stored variant ID with the current one
    const variantIdInput = this.querySelector('.variant-id');
    if (variantIdInput && variantId) {
      variantIdInput.value = variantId;
      console.log(`Updated variant ID in Klaviyo BIS form to: ${variantId}`);
    }

    // Reset our form event listeners to ensure they work with the new DOM
    if (this.form) {
      this.form.removeEventListener('submit', this.onSubmitHandler);
      this.form = this.querySelector('form');
      if (this.form) {
        this.form.addEventListener('submit', this.onSubmitHandler.bind(this));
      }
    }

    // Update close button listeners
    this.querySelectorAll('.klaviyo-bis-close').forEach((elem) => {
      elem.removeEventListener('click', this.onClickHandler);
      elem.addEventListener('click', this.onClickHandler.bind(this));
    });
  }

  /**
   * Subscribe to variant change events for this specific product form
   */
  subscribeToVariantChanges() {
    subscribe(PUB_SUB_EVENTS.variantChange, (event) => {
      // Only handle events for our specific product form
      if (event.data.productFormId === this.productFormId) {
        // Store the variant data for title display purposes
        this.currentVariant = event.data.variant;

        // Update the product title in the form
        const productTitle = this.querySelector('input[name="product_title"]');
        if (productTitle && this.currentVariant && this.currentVariant.name) {
          productTitle.value = this.currentVariant.name;
          console.log(`Updated product title for ${this.productFormId} to ${this.currentVariant.name}`);
        }

        // Update the variant ID in the form's hidden input
        const variantIdInput = this.querySelector('.variant-id');
        if (variantIdInput && this.currentVariant && this.currentVariant.id) {
          variantIdInput.value = this.currentVariant.id;
          console.log(`Updated variant ID for ${this.productFormId} to ${this.currentVariant.id}`);
        }
      }
    });
  }

  /**
   * Sets up event delegation for BIS trigger buttons
   */
  setupBisTriggers() {
    // Use event delegation at document level to catch all trigger clicks
    document.addEventListener(
      'click',
      (event) => {
        const trigger = event.target.closest('.klaviyo-bis-trigger');
        if (trigger) {
          // Check if this trigger belongs to our product form
          const productForm = trigger.closest(`#productInformationForm-${this.productFormId}`);
          if (productForm) {
            event.preventDefault();
            event.stopPropagation();
            this.showPopup();
            return false;
          }
        }
      },
      { capture: true }
    );
  }

  /**
   * Shows the BIS popup and updates the variant information
   */
  showPopup() {
    // Show the popup
    this.classList.remove('hidden');
    this.classList.add('flex');

    // Get the product title from the component
    const productTitleElem = this.querySelector('.product-title');

    if (productTitleElem && this.currentVariant && this.currentVariant.name) {
      productTitleElem.textContent = this.currentVariant.name;
    }
  }

  /**
   * Sends the BIS request to Klaviyo
   */
  sendDataToKlaviyo(email, productId) {
    const url = 'https://a.klaviyo.com/client/back-in-stock-subscriptions/?company_id=U8rCt6';
    const productString = '$shopify:::$default:::' + productId;
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        revision: '2023-09-15',
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        data: {
          type: 'back-in-stock-subscription',
          attributes: {
            channels: ['EMAIL'],
            profile: { data: { type: 'profile', attributes: { email: email } } }
          },
          relationships: {
            variant: { data: { type: 'catalog-variant', id: productString } }
          }
        }
      })
    };

    fetch(url, options)
      .then((res) => {
        if (res.ok) {
          this.querySelector('form').textContent = 'Thank You!';
        }
        return res.json();
      })
      .then((json) => console.log(json))
      .catch((err) => console.log(err));
  }

  /**
   * Subscribes the user to the mailing list in Klaviyo
   */
  sendListToKlaviyo(email) {
    const url = 'https://a.klaviyo.com/client/subscriptions/?company_id=U8rCt6';
    const options = {
      method: 'POST',
      headers: { revision: '2023-09-15', 'content-type': 'application/json' },
      body: JSON.stringify({
        data: {
          type: 'subscription',
          attributes: {
            custom_source: 'Back in stock form',
            profile: {
              data: {
                type: 'profile',
                attributes: {
                  email: email
                }
              }
            }
          },
          relationships: { list: { data: { type: 'list', id: 'XWPEqh' } } }
        }
      })
    };

    fetch(url, options)
      .then((res) => res.json())
      .then((json) => console.log(json))
      .catch((err) => console.error('error:' + err));
  }

  /**
   * Handles form submission
   */
  onSubmitHandler(event) {
    event.preventDefault();

    // Get email from this specific form using the proper ID format from the template
    const email = this.querySelector(`#${this.uniqueId}-email`).value;

    // Get the product ID specific to this form
    const productId = this.querySelector('.variant-id').value;

    // Use the proper ID format from the template for the checkbox
    const subscribeToList = this.querySelector(`#${this.uniqueId}-subscribe-to-list`).checked;

    console.log(`Submitting for product form ${this.productFormId}:`, email, productId, subscribeToList);

    this.sendDataToKlaviyo(email, productId);
    if (subscribeToList) this.sendListToKlaviyo(email);
  }

  /**
   * Closes the popup
   */
  onClickHandler(event) {
    this.classList.add('hidden');
    this.classList.remove('flex');
  }
}

customElements.define('klaviyo-bis', BISForm);
